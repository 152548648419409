import React, { useEffect, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import s from './Product.module.scss';
import Select from '../../../../components/UI/Select/Select';
import DeleteIcon from '../../../../icons/Delete';
import Picture from '../../../../components/Pictue/Picture';
import axios from 'axios';
import { useAuth } from '../../../../Contaxt/AuthContext/AuthContext';

const Product = ({
  initialValues = {},
  setIsProductCreated,
  getData,
  setLoading,
}) => {
  const types = [
    'Appetizers',
    'Salads',
    'Speciality Calzone',
    'Speciality Pizza',
    'Pasta',
    'Burgers',
    'Subs & Sandwiches',
    'Drinks',
  ];

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      type: 'Select Type',
      englishName: '',
      spanishName: '',
      price: '',
      smallPrice: '',
      largePrice: '',
      englishDescription: '',
      spanishDescription: '',
    },
    mode: 'onChange',
  });

  const formData = watch();
  const [isChanged, setIsChanged] = useState(false);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState('');
  const [pictureKey, setPictureKey] = useState(0);
  const { token } = useAuth();
  axios.defaults.headers.common['authorization'] = `Bearer: ${token}`;

  const createProduct = async (url, data) => {
    setLoading(true);
    try {
      console.log(`Create Product send to ${url} `, data);
      setIsProductCreated(false);
      const response = await axios.post(url, data);
      console.log('Create Product Response >>>', response);
      await getData();
    } catch (error) {
      console.log('Create Product Error >>>', error);
    } finally {
      setLoading(false);
    }
  };

  const updateProduct = async (url, data) => {
    setLoading(true);
    try {
      console.log(`Update Product send to ${url}`, data);

      const response = await axios.put(url, data);
      console.log('Update Product Response >>>', response);
      await getData();
    } catch (error) {
      console.log('Update Product Error >>>', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteProduct = async id => {
    setLoading(true);
    try {
      console.log('Delete Product send >>>', id);
      const response = await axios.delete(
        `https://api.oldsicily.com/api/product/${id}`,
      );
      console.log('Delete Product Response >>>', response);
      await getData();
    } catch (error) {
      console.log('Delete Product Error >>>', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length > 0) {
      Object.keys(initialValues).forEach(key =>
        setValue(key, initialValues[key]),
      );
      if (initialValues.image) {
        setImage(initialValues.image);
      }
    }
  }, [initialValues, setValue]);

  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length > 0) {
      const isFormChanged =
        Object.keys(initialValues).some(
          key => formData[key] !== initialValues[key],
        ) || image !== initialValues.image;
      setIsChanged(isFormChanged);
    } else {
      setIsChanged(true);
    }
  }, [formData, initialValues, image]);

  function appendToFormData(data, formData) {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  }

  const onSubmit = data => {
    const priceFields = ['price', 'smallPrice', 'largePrice'];
    for (let field of priceFields) {
      if (data[field] && !/^\d*\.?\d*$/.test(data[field])) {
        alert(
          `The field ${field} contains non-numeric characters or more than two decimal places.`,
        );
        return;
      }
    }

    if (
      !isValid ||
      !image ||
      (initialValues && Object.keys(initialValues).length > 0 && !isChanged)
    ) {
      alert('The form contains empty fields, incorrect data, or no changes.');
      return;
    }

    const url = initialValues.id
      ? `https://api.oldsicily.com/api/product/${initialValues.id}`
      : 'https://api.oldsicily.com/api/product/create';
    const payload = { ...data, image };
    delete payload.id;

    const formData = new FormData();
    appendToFormData(payload, formData);

    initialValues.id
      ? updateProduct(url, formData)
      : createProduct(url, formData);
  };

  const handleKeyPress = event => {
    if (
      !/[\d.]$/.test(event.key) ||
      (event.key === '.' && event.target.value.includes('.'))
    ) {
      event.preventDefault();
    }
  };

  const handleInput = event => {
    const { value } = event.target;
    if (!/^\d*\.?\d*$/.test(value)) {
      event.target.value = value
        .replace(/[^.\d]/g, '')
        .replace(/(\..*)\./g, '$1');
    }
  };

  const handleImageChange = useCallback(newImage => {
    setImage(newImage);
    setIsChanged(true);
    if (!newImage) {
      setImageError('Image is required.');
    } else {
      setImageError('');
    }
  }, []);

  const renderInput = (name, placeholder) => (
    <label className={s.description__label}>
      <input
        {...register(name, { required: true })}
        className={classNames(s.description__input, {
          [s.empty]: !formData[name],
        })}
        type="text"
        placeholder={placeholder}
        onKeyPress={name.includes('Price') ? handleKeyPress : undefined}
        onInput={name.includes('Price') ? handleInput : undefined}
      />
    </label>
  );

  return (
    <div className={s.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
        <div className={classNames(s.picture, { [s.empty]: !image })}>
          <Picture
            key={pictureKey}
            initialImage={image}
            onImageChange={newImage => {
              handleImageChange(newImage);
              setPictureKey(pictureKey + 1);
            }}
          />
          {imageError && <span className={s.error}>{imageError}</span>}
        </div>
        <div className={s.properties}>
          <div className={s.name}>
            {renderInput('englishName', 'English')}
            {renderInput('spanishName', 'Spanish')}
          </div>
          <div className={s.typesAndPrice}>
            <div
              className={classNames(s.types, {
                [s.empty]: !formData.type || formData.type === 'Select Type',
              })}>
              <Controller
                name="type"
                control={control}
                rules={{ validate: value => value !== 'Select Type' }}
                render={({ field }) => (
                  <Select
                    options={types}
                    value={field.value}
                    setValue={field.onChange}
                  />
                )}
              />
            </div>
            <div className={s.prices}>
              {formData.type === 'Speciality Pizza' ? (
                <div className={s.prices__pizza}>
                  {['smallPrice', 'price', 'largePrice'].map((name, index) => (
                    <label key={index} className={s.pricePizza__label}>
                      <input
                        {...register(name, { required: true })}
                        className={classNames(s.pricePizza__input, {
                          [s.empty]: !formData[name],
                        })}
                        type="text"
                        placeholder={
                          name === 'price' ? 'M' : name.charAt(0).toUpperCase()
                        }
                        onKeyPress={handleKeyPress}
                        onInput={handleInput}
                      />
                    </label>
                  ))}
                </div>
              ) : (
                <div className={s.price}>
                  <div className={s.price__label}>
                    <input
                      {...register('price', { required: true })}
                      type="text"
                      className={classNames(s.price__input, {
                        [s.empty]: !formData.price,
                      })}
                      placeholder="Price"
                      onKeyPress={handleKeyPress}
                      onInput={handleInput}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={s.description}>
            {renderInput('englishDescription', 'English')}
            {renderInput('spanishDescription', 'Spanish')}
          </div>
        </div>
        <div className={s.save}>
          <button
            type="submit"
            className={s.save__button}
            disabled={
              !isValid ||
              !image ||
              (initialValues &&
                Object.keys(initialValues).length > 0 &&
                !isChanged)
            }>
            {initialValues.id ? 'Change' : 'Create'}
          </button>
        </div>
        <div className={s.delete}>
          <button
            onClick={() => deleteProduct(initialValues.id)}
            className={s.delete__button}
            type="button">
            <DeleteIcon />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Product;
